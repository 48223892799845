// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/projects/otokumes1.png",
    title: "Automatic Chicken Coop",
    description:                  
      "Ensuring automatic operation of the chicken coop" +
      "It ensures regular and continuous operation of chicken coops and facilitates management.",
    tech_stack: ["Firebase", "C++", "IOT", "Raspberry Pi", "Python", "Nvidia Jetson Xavier"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/renr-responsive.png",
    title: "MY-Burden",
    description:
      "Provides business process management and vehicle tracking." +
      "It ensures increased productivity and successful work.",
    tech_stack: ["NodeJs", "React", "MongoDB", "SocketIo"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/abs.png",
    title: "Smart Building Systems",
    description:
      "It is a technological system that provides automation and control by integrating electronic and mechanical systems within the building.",
    tech_stack: ["React", "Firebase", "C", "C++", "STM", "FreeRTOS", "ESP", "IOT"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/ga_proje.png",
    title: "Creating the ideal route with genetic algorithm ",
    description:
      "To create the most ideal route as the crow flies between selected points on the map",
    tech_stack: ["Flask", "Python", "Anaconda"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/renr-responsive.png",
    title: "Business Management Systems",
    description:
      "Cloud based project, business management system",
    tech_stack: ["ReactJs", "NodeJs", "MongoDB", "NextJs", "AWS", "MaterialUI"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/elyazisi_proje.png",
    title: "Handwriting identification with ANN",
    description:
      "ANN model that allows it to select the text on the image containing the given handwriting.",
    tech_stack: ["Python", "Keras"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/ida1.png",
    title: "Unmanned Amphibious Vehicle",
    description:
      "It is a vehicle that can travel both on land and in water and is designed to perform various tasks.",
    tech_stack: ["C++", "C", "STM", "LattePanda"],
    github_url: "",
    demo_url: "",
  },
  {
    img: "/projects/UAV-GPS.png",
    title: "UAV Image and GPS Processing",
    description:
      "Display and tracking of all animals thanks to the Yolo model trained on the UAV image by processing" + 
      "the coordinate information of the UAV camera and the animals..",
    tech_stack: ["Python", "YOLOv8", "Flask", ""],
    github_url: "",
    demo_url: "",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "React Native",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/cassandra/cassandra-original.svg",
    name: "Cassandra",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/materialui/materialui-original.svg",
    name: "Material UI",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
    name: "C#",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
    name: "C++",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/matlab/matlab-original.svg",
    name: "MatLab",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/kaggle/kaggle-original.svg",
    name: "Kaggle",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/keras/keras-original.svg",
    name: "Keras",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/tensorflow/tensorflow-original.svg",
    name: "TensorFlow",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/opencv/opencv-original.svg",
    name: "OpenCV",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/arduino/arduino-original.svg",
    name: "Arduino",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/raspberrypi/raspberrypi-original.svg",
    name: "Raspberry Pi",
  },
];
